import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DvmModule } from '@3ddv/ngx-dvm-internal';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './shared/components/layout/main-layout/main-layout.component';
import { AppConfig } from 'src/configuration/configuration';
import { csrfInterceptor } from './core/interceptors/csrf.interceptor';
import { TopbarComponent } from './shared/components/globals/topbar/topbar.component';
import { apiUrlInterceptor } from './core/interceptors/api-url.interceptor';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BuyTicketsModule } from './views/buy-tickets/buy-tickets.module';
import { BuyPackagesModule } from './views/buy-packages/buy-packages.module';
import { CheckoutModule } from './views/checkout/checkout.module';
import { SummaryModule } from './views/summary/summary.module';
import { AppInit } from 'src/configuration/app-init';
import { LoginModule } from './views/login/login.module';
import { NavbarModule } from './shared/components/globals/navbar/navbar.module';
import { ExchangeModule } from './views/exchange/exchange.module';
import { ReservedTicketsModule } from './views/reserved-tickets/reserved-tickets.module';
import { MyAccountModule } from './views/my-account/my-account.module';


@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    TopbarComponent,
  ],
  imports: [
    // ANGULAR
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,

    //LOGIN
    LoginModule,

    //BUY TICKETS
    BuyTicketsModule,

    // BUY PACKAGES
    BuyPackagesModule,

    // RESERVE TICKETS
    ReservedTicketsModule,

    // TICKET EXCHANGE
    ExchangeModule,

    // CHECKOUT
    CheckoutModule,

    // SUMMARY
    SummaryModule,
    
    // MY ACCOUNT
    MyAccountModule,

    // NAVBAR
    NavbarModule,

    // DVM
    DvmModule,

    // SWEETALERT 2
    SweetAlert2Module.forRoot(),
  ],
  providers: [
    // CONFIGURATION
    AppConfig,
    AppInit,

    // INTERCEPTORS
    csrfInterceptor,
    apiUrlInterceptor,

  ],
  bootstrap: [AppComponent]
})
export class AppModule {}