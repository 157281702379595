
<!-- Main Navbar -->
<div (mouseover)="isExtended = true" (mouseleave)="isExtended = false" 
    class="bg-main text-white fixed top-0 w-full h-16 lg:h-full lg:items-start lg:flex-col z-40 lg:w-20 transition-all ease-in-out duration-300 lg:hover:w-[var(--navbar-max-w)] ">
    
    <!-- Inner Wrapper -->
    <div class="flex h-full p-4 lg:top-0 lg:p-0 lg:w-[var(--navbar-width)] lg:hover:w-auto items-center lg:items-start lg:flex-col lg:py-5 lg:hover:shadow-xl">
  
        <!-- Logo -->
        <div class="h-12 w-12 flex items-center mr-3 lg:mr-0 lg:ml-4 transition-transform lg:hover:scale-110 cursor-pointer ease-in">
            <app-logo/>
        </div>

        <!-- Client and App Name | Mobile-->
        <div class="lg:hidden">
            <h3 class="font-bold text-sm">
                {{ client }}
                <span class="font-normal opacity-80">
                    | {{ appName }}
                </span>
            </h3>
        </div>

        <!-- Nav Button | Mobile -->
        <div class="ml-auto lg:hidden">
            <button class="flex items-center rounded-full p-2 hover:bg-secondary/20 transition-colors outline-none" (click)="toggleMobileMenu()">
                <svg *ngIf="!isOpen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
                <svg *ngIf="isOpen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
                
            </button>
        </div>

        <!-- Nav Links | Desktop -->
        <div *ngIf="isDesktop"  class="hidden lg:flex flex-col justify-between h-full w-full overflow-hidden mt-4 ">
            <div class="whitespace-nowrap">
                <app-navlink *ngFor="let link of routes" [link]="link" [onlyLetter]="!isExtended" (click)="navigate(link.slug)" />
            </div>
            <div class="whitespace-nowrap">
                <app-information (navigate)="navigate($event)" [onlyLetter]="!isExtended"/>
                <app-logout [onlyLetter]="!isExtended"/>
            </div>
        </div>

    </div> 
    <!-- End Inner Wrapper -->
</div>

<!-- Nav Links | Mobile -->
<div *ngIf="!isDesktop" class="relative lg:hidden">
    <div [ngStyle]="{'transform': isOpen ? 'translateY(0%)' : 'translateY(-100%)'}"
         class="fixed top-0 left-0 h-[100dvh] w-full transition-all duration-700 ease-in-out z-30 bg-main overflow-hidden">
        <div class="container px-5 flex flex-col h-full justify-between">
            <div class="mt-24">
                <!-- Links -->
                <app-navlink *ngFor="let link of routes" [link]="link" (click)="navigate(link.slug)"/>
            </div>
            <div class="mb-5">
                <hr class="opacity-40">
                <div class="flex flex-col text-white mt-5">
                    <!-- Information -->
                    <app-information (navigate)="navigate($event)"/>
                    <!-- Logout -->
                    <app-logout/>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Empty Shell -->
<!-- 
Because the navbar is fixed, we need a empty div with the same size to append
to the grid, if not, the grid would not be able to calculate the navbar width 
and the main container will undergo the navbar  
-->
<div class="block h-16 bg-main lg:w-[var(--navbar-width)] lg:h-full"></div>

