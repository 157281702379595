<main>
    <section class="grid grid-cols-1 grid-rows-3 lg:grid-cols-12 lg:grid-rows-1 min-h-[100dvh] max-h-screen w-screen overflow-hidden">
        <!-- Image -->
        <div class="lg:col-span-8">
            <ng-content select="[image]"></ng-content>
        </div>
        <!-- Form -->
        <div class="row-span-2 lg:col-span-4 dark:bg-gray-900">
            <ng-content select="[form]"></ng-content>
        </div>
    </section>
</main>
