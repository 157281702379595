<section class="h-full lg:overflow-hidden">
    
    <div class="md:h-1/4 flex flex-col items-center justify-center lg:gap-y-3">

        <h1 class="py-12 md:py-0 text-darkgray dark:text-lightgray/60 font-bold text-3xl mb-4 lg:text-4xl">
            My Account
        </h1>
        
        <div class="bg-secondary w-full md:bg-transparent">
            <p class="text-white md:text-secondary text-xs text-center px-3 max-w-sm mx-auto sm:max-w-none py-4 md:text-sm lg:text-base">
                Please ensure your address details (including state & postal code) are correct before making your purchase.
            </p>
        </div>

    </div>

    <div class="h-auto lg:h-3/4 lg:overflow-y-auto lg:relative bg-white">
       
        <!-- NAVIGATION -->
        <nav class="lg:sticky bg-inherit lg:top-0 lg:shadow lg:shadow-white/80 flex border-b border-lightgray justify-around md:justify-center font-semibold font-main">

            <div class="w-1/3 lg:w-1/6 text-center h-full cursor-pointer text-darkgray/50" *ngFor="let route of childRoutes">
                
                <a [routerLink]="route.path" routerLinkActive="after:absolute after:left-0 after:-bottom-[2px] after:w-full after:h-1 after:bg-main text-darkgray " class="h-full w-full block relative py-2 sm:p-3">
                    <span class="text-xs md:text-base">
                        {{route.title}}
                    </span>
                
                </a>
            </div>

        </nav>

       
        <!-- CONTENT -->
        <div class="py-5 overflow-y-auto">
            <router-outlet></router-outlet>
        </div>

    </div>

</section>