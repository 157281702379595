import { Component, OnInit, inject } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { User } from 'src/app/shared/models/user.model';
import { APP_CONFIG } from 'src/configuration/configuration';
import { Configuration } from 'src/app/shared/models/configuration.model';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})

export class TopbarComponent {

  private auth:          AuthService   = inject(AuthService);  // Servicio de Auth
  private configuration: Configuration = inject(APP_CONFIG);   // Servicio de Configuración
  private isTakeOver:    boolean       = false;                // Modo Take Over
 
  protected appName: string = this.configuration.appName;      // Nombre de la App
  protected client:  string = this.configuration.clientName;   // Nombre del cliente

  protected name:    User['tdc_info']['first_name'] = this.auth.userData()?.tdc_info.first_name as string;  // Nombre del User
  protected surname: User['tdc_info']['last_name']  = this.auth.userData()?.tdc_info.last_name as string;   // Apellidos del User
  protected account: User['tdc_info']['id']         = this.auth.userData()?.tdc_info.id as number;          // Account ID del User

}
