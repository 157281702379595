<div class="fixed z-20 lg:static w-full flex justify-between lg:items-center p-4 lg:p-6 bg-white lg:bg-transparent dark:bg-gray-900 lg:border-none shadow-lg shadow-secondary/10 lg:shadow-none ">
 
    <div class="hidden lg:block sticky top-0 text-2xl font-medium text-darkgray dark:text-lightgray">
        <h1>
        {{client}}
            <span class="opacity-50">
            | {{appName}}
            </span>
        </h1>
    </div>

    <div class="flex text-main dark:text-secondary text-[.70rem] lg:text-base justify-center w-full lg:w-auto">
        <h3>
            Welcome, {{name}} {{surname}} &nbsp;
        </h3>
        <p class="font-bold">
            |&nbsp; Supporter Number: {{account}}
        </p>
  </div>

</div>

<!-- Empty Shell -->
<div class="h-12 lg:hidden"></div>
