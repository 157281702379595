<div class="flex font-main text-white text-2xl lg:text-base items-center my-4 lg:my-0 lg:py-2 group cursor-pointer w-fit lg:hover:bg-secondary">

    <div class="group-hover:bg-secondary  w-10 h-10 flex justify-center items-center lg:w-[80px]">
        <span class="text-3xl lg:text-4xl">
            {{link.letter}}
        </span>
    </div>

    <div *ngIf="!onlyLetter" class="flex group-hover:underline lg:group-hover:no-underline ml-8 lg:w-52">
        <span class="lg:uppercase ">
            {{ link.text }}
        </span>
    </div>

</div>
