import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { loginGuard } from './core/guards/login.guard';
import { MainLayoutComponent } from './shared/components/layout/main-layout/main-layout.component';
import { authGuard } from './core/guards/auth.guard';
import { BuyTicketsComponent } from './views/buy-tickets/buy-tickets.component';
import { BuyPackagesComponent } from './views/buy-packages/buy-packages.component';
import { InformationPageComponent } from './views/information-page/information-page.component';
import { ExchangeComponent } from './views/exchange/exchange.component';
import { ReservedTicketsComponent } from './views/reserved-tickets/reserved-tickets.component';
import { FriendsFamilyComponent } from './views/friends-family/friends-family.component';
import { MyAccountComponent } from './views/my-account/my-account.component';

const routes: Routes = [
  // Redirect
  {
    path: '',
    redirectTo: 'buy-tickets',
    pathMatch: 'full',
  },
  // App
  {
    path: '',
    component: MainLayoutComponent,
    canActivate:[authGuard],
    children:[
      // BUY TICKETS
      {
        path: 'buy-tickets',
        component: BuyTicketsComponent,
        loadChildren: () => import('./views/buy-tickets/buy-tickets.module').then(m => m.BuyTicketsModule)
      },
      // BUY PACKAGES
      {
        path: 'buy-packages',
        component: BuyPackagesComponent,
        loadChildren: () => import('./views/buy-packages/buy-packages.module').then(m => m.BuyPackagesModule),
      },
      // RENEW TICKETS
      {
        path: 'renew-tickets',
        component: ReservedTicketsComponent,
        loadChildren: () => import('./views/reserved-tickets/reserved-tickets.module').then(m => m.ReservedTicketsModule),
      },
      // TICKET EXCHANGE
      {
        path: 'exchange',
        component: ExchangeComponent,
        loadChildren: () => import('./views/exchange/exchange.module').then(m => m.ExchangeModule),
        data: {
          type: 'Men'
        }
      },
      // WOMEN TICKET EXCHANGE
      {
        path: 'women-texchange',
        component: ExchangeComponent,
        loadChildren: () => import('./views/exchange/exchange.module').then(m => m.ExchangeModule),
        data: {
          type: 'Women',
        }
      },
      // FRIENDS & FAMILY
      {
        path: 'friends-family',
        component: FriendsFamilyComponent,
        loadChildren: () => import('./views/friends-family/friends-family.module').then(m => m.FriendsFamilyModule)
      },
      // MY ACCOUNT
      // {
      //   path: 'my-account',
      //   component: MyAccountComponent,
      //   loadChildren: () => import('./views/my-account/my-account.module').then(m => m.MyAccountModule)
      // },
      // INFORMATION
      {
        path: 'information',
        component: InformationPageComponent,
        title: 'Information'
      }
    ]
  },
  // LOGIN
  {
    path: 'login',
    component: LoginComponent,
    data:{
      title: 'Login'
    },
    canActivate: [loginGuard]
  },
  // RESET PASSWORD
  {
    path: 'reset-password',
    canActivate: [loginGuard],
    component:  LoginComponent,
    pathMatch: 'full',
  },
  // ADD ASSSOCIATION
  {
    path: 'association',
    canActivate: [loginGuard],
    component:  LoginComponent,
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', enableViewTransitions:true})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
