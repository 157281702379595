<app-login-layout>
    <!-- Image Section | Img + Radial Gradient -->
    <div class="relative overflow-hidden h-full w-full" image>
        <div class="absolute z-10 top-0 left-0 h-full w-full bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-transparent via-blue-800/20 to-blue-900/30"></div>
        <img fetchpriority="high" [src]="background" class="h-full lg:h-screen w-full object-cover object-center" [alt]="'Login Background | ' + client">
    </div>

    <!-- Login Form -->
    <div class="container py-2 px-6 lg:px-20 mx-auto flex flex-col items-center h-full overflow-hidden lg:justify-between" form>

        <!-- Label -->
        <div>
            <h1 class="text-center font-bold text-gray-500 font-main text-xs md:text-base mt-2">
                {{client}}
                <span class="font-normal">
                    | {{appName}}
                </span>
            </h1>
        </div>

        <!-- Logo -->
        <div>
            <div class="absolute top-5 left-5 h-16 w-16 lg:h-28 lg:w-28 z-10 md:static">
                <app-logo></app-logo>
            </div>
        </div>

        <!-- Forms + Texts -->
        <ng-container *ngIf="showLoginTexts else blankSpace">

            <div class="w-full mt-6 lg:mt-0 lg:h-4/6">      
                <h2 class=" text-secondary font-medium dark:text-gray-500 lg:text-lg">
                    Log In.
                </h2>
                <p class="font-main text-3xl font-medium text-main dark:text-secondary lg:text-4xl">
                    Welcome Back!
                </p>          
                <!-- Login Form -->
               <app-login-form        *ngIf="view === 'login'" (changeView)="changeLoginView($event)"/>
               <!-- Reset Password -->
               <app-login-reset       *ngIf="view === 'reset'" (changeView)="changeLoginView($event)"/>
               <!-- New Account -->
               <app-login-new-account *ngIf="view === 'new'"   (changeView)="changeLoginView($event)"/>
            </div>

        </ng-container>

        <ng-template #blankSpace>
            <div class="w-full mt-6 flex lg:mt-0 lg:h-4/6 items-center justify-center">
                <app-login-association *ngIf="view === 'association'" (changeView)="changeLoginView($event)"></app-login-association>
            </div>
        </ng-template>

    </div>
</app-login-layout>
