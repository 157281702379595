<div class="flex items-center group w-fit cursor-pointer lg:py-2 lg:hover:bg-secondary" (click)="logout()">
    <div class="flex lg:w-[80px] lg:justify-center">
        <i class="transition-transform group-hover:scale-105 pl-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
                <path stroke-linecap="round" stroke-linejoin="round" d="M5.636 5.636a9 9 0 1012.728 0M12 3v9" />
            </svg> 
        </i>
    </div>
    <div *ngIf="!onlyLetter">
        <p class="text-xl ml-7 lg:text-base lg:uppercase lg:w-52">
            Logout
        </p>
    </div>
</div>