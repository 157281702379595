import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LogoComponent } from 'src/app/shared/components/globals/logo/logo.component';
import { PersonalDataComponent } from '../personal-data/personal-data.component';


const routes: Routes = [
    {
        path: '',
        redirectTo: 'personal-data',
        pathMatch: 'full',
        title: 'My Account | Personal Data',
    },
    {
        path: 'personal-data',
        component: PersonalDataComponent,
        loadChildren: ()=> import('../personal-data/personal-data.module').then(m => m.PersonalDataModule)
    },
    {
        path: 'my-tickets',
        component: LogoComponent,
    },
    {
        path: 'order-history',
        component: LogoComponent,
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MyAccountRoutingModule { }