import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css']
})
export class InformationComponent {

  @Input() onlyLetter!: boolean;
  
  @Output()
  navigate: EventEmitter<string> = new EventEmitter<string>();

  protected initNavigation(){
    this.navigate.emit('information');
  }
  
}
