<div class="flex items-center mb-4  lg:mb-0 lg:py-2 lg:w-fit group lg:hover:bg-secondary cursor-pointer" (click)="initNavigation()">
    <div class="flex lg:w-[80px] lg:justify-center items-center">
        <i class="transition-transform group-hover:scale-105 pl-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
            </svg> 
        </i>
    </div>
    <div *ngIf="!onlyLetter">
        <p  class="text-xl lg:text-base lg:uppercase ml-7 lg:w-52 ">
            Information
        </p>
    </div>
</div>